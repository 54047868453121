.modalform {
  z-index: 1007;
  position: fixed;
  top: 22vh;
  left: 10%;
  width: 80%;
  background: #3a3a3a;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  border-radius: 8px;
}

.modalform__header {
  width: 100%;
  padding: 1rem 0.5rem;
  background: #3a3a3a;
  color: white;
  border-radius: 8px;
}

.modalform__header h2 {
  color: white;
  margin: 0.5rem;
}

.modalform__content {
  color: white;
  padding: 1rem 0.5rem;
}

.modalform__footer {
  padding: 1rem 0.5rem;
}

@media (min-width: 768px) {
  .modalform {
    left: calc(50% - 20rem);
    width: 40rem;
  }
}

.modalform-enter {
  transform: translateY(-10rem);
  opacity: 0;
}

.modalform-enter-active {
  transform: translateY(0);
  opacity: 1;
  transition: all 200ms;
}

.modalform-exit {
  transform: translateY(0);
  opacity: 1;
}

.modalform-exit-active {
  transform: translateY(-10rem);
  opacity: 0;
  transition: all 200ms;
}
