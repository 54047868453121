@import url('https://fonts.googleapis.com/css2?family=Fondamento:ital@0;1&display=swap');

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.home,
.main,
.contact,
.bookone,
.login
.donate {
  display: flex;
  height: 100vh; /*this is the view area of the page */
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}