@import url('https://fonts.googleapis.com/css2?family=Bilbo+Swash+Caps&family=Raleway&display=swap');
.raleway__font{

font-family: 'Raleway', san-serif;
}

.bilbo__font{
font-size: 28px; 
font-family: 'Bilbo Swash Caps', cursive;
}

.li__styles{
text-decoration: none;
}