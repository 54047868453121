@import url(https://fonts.googleapis.com/css2?family=Fondamento:ital@0;1&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Bilbo+Swash+Caps&family=Raleway&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Bilbo+Swash+Caps&family=Raleway&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Bilbo+Swash+Caps&family=Raleway&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Bilbo+Swash+Caps&family=Raleway&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Bilbo+Swash+Caps&family=Raleway&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #1f1f1f;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.home,
.main,
.contact,
.bookone,
.login
.donate {
  display: flex;
  height: 100vh; /*this is the view area of the page */
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.75);
  z-index: 1006;
}

.modalform {
  z-index: 1007;
  position: fixed;
  top: 22vh;
  left: 10%;
  width: 80%;
  background: #3a3a3a;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  border-radius: 8px;
}

.modalform__header {
  width: 100%;
  padding: 1rem 0.5rem;
  background: #3a3a3a;
  color: white;
  border-radius: 8px;
}

.modalform__header h2 {
  color: white;
  margin: 0.5rem;
}

.modalform__content {
  color: white;
  padding: 1rem 0.5rem;
}

.modalform__footer {
  padding: 1rem 0.5rem;
}

@media (min-width: 768px) {
  .modalform {
    left: calc(50% - 20rem);
    width: 40rem;
  }
}

.modalform-enter {
  transform: translateY(-10rem);
  opacity: 0;
}

.modalform-enter-active {
  transform: translateY(0);
  opacity: 1;
  transition: all 200ms;
}

.modalform-exit {
  transform: translateY(0);
  opacity: 1;
}

.modalform-exit-active {
  transform: translateY(-10rem);
  opacity: 0;
  transition: all 200ms;
}

.button {
  font: inherit;
  padding: 0.3rem .5rem;
  border: 1px solid #ebdb4f;
  border-radius: 4px;
  background: #987501;
  color: white;
  cursor: pointer;
  margin-right: 1rem;
  text-decoration: none;
  display: inline-block;
}

.button:focus {
  outline: none;
}

.button:hover,
.button:active {
  background:#987501;
  border-color:#987501;
}

.button--inverse {
  background: transparent;
  color: #987501;
}

.button--inverse:hover,
.button--inverse:active {
  color: white;
  background: #987501;
}

.button--danger {
  background: #774102;
  border-color: #d68513;
}

.button--danger:hover,
.button--danger:active {
  background: #f34343;
  border-color: #f34343;
}

.button:disabled,
.button:hover:disabled,
.button:active:disabled {
  background: #ccc;
  color: #979797;
  border-color: #ccc;
  cursor: not-allowed;
}

.button--small {
  font-size: 0.8rem;
}

.button--big {
  font-size: 1.5rem;
}
.lds-dual-ring {
  display: inline-block;
  width: 64px;
  height: 64px;
}

.lds-dual-ring:after {
  content: ' ';
  display: block;
  width: 46px;
  height: 46px;
  margin: 1px;
  border-radius: 50%;
  border: 5px solid #510077;
  border-color: #510077 transparent #510077 transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}

.loading-spinner__overlay {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

*:focus {
    outline: 0 !important;
}

.raleway__font{    
font-family: 'Raleway', san-serif;
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.75);
  z-index: 10;
}

.modal {
  z-index: 1003;
  position: fixed;
  top: 5vh;
  left: 10%;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  border-radius: 8px;
}


.modal__header h2 {
  margin: 0.5rem;
}

.modal__content {
  padding: 1rem 0.5rem;
}

.modal__footer {
  padding: 1rem 0.5rem;
}

@media (min-width: 768px) {
  .modal {
    left: calc(13% - 2rem);
    width: 90rem;
  }
}

.modal-enter {
  transform: translateY(-10rem);
  opacity: 0;
}

.modal-enter-active {
  transform: translateY(0);
  opacity: 1;
  transition: all 200ms;
}

.modal-exit {
  transform: translateY(0);
  opacity: 1;
}

.modal-exit-active {
  transform: translateY(-10rem);
  opacity: 0;
  transition: all 200ms;
}

.form-control {
  margin: 1rem 0;
}

.form-control label,
.form-control input,
.form-control textarea {
  display: block;
}

.form-control label {
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.form-control input,
.form-control textarea {
  width: 100%;
  font: inherit;
  border: 1px solid #ccc;
  background: #f8f8f8;
  padding: 0.15rem 0.25rem;
}

.form-control input:focus,
.form-control textarea:focus {
  outline: none;
  background: #ebebeb;
  border-color: #510077;
}

.form-control--invalid label,
.form-control--invalid p {
  color: red;
}

.form-control--invalid input,
.form-control--invalid textarea {
  border-color: red;
  background: #ffd1d1;
}
.raleway__font{

font-family: 'Raleway', san-serif;
}

.bilbo__font{
font-size: 28px; 
font-family: 'Bilbo Swash Caps', cursive;
}

.li__styles{
text-decoration: none;
}
.maintext{
    
font-family: 'Raleway', san-serif;
}

.thep{
font-family: 'Bilbo Swash Caps', cursive;
}
.bilbo__font{
   font-family: 'Bilbo Swash Caps', cursive;
}

.raleway__font{
        
font-family: 'Raleway', san-serif;
}
.bio__font{
   font-family: 'Bilbo Swash Caps', cursive;
}

.info__font{
        
font-family: 'Raleway', san-serif;
}
